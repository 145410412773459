/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react';
import { useTheme } from 'styled-components';

import { LatLngBounds, LatLngExpression } from 'leaflet';
import { Polygon, useMap } from 'react-leaflet';
import {
    ROUTES_AUTH,
    ROUTES_OWNER_PANEL,
    trackingEvents,
} from '@constants';

import { Map } from 'components'
import { useAuth, useLayout, useTracking } from 'contexts';

import { useGetOwnerFieldList } from 'services';

export const PanelOwnerFieldList = () => {
    const { idAccess } = useAuth();
    const { initLayout } = useLayout();
    const { trackEvent } = useTracking();

    const { colors } = useTheme();

    useEffect(() => {
        initLayout('Áreas de Terra', [
            {
                label: 'Seleção de Conta',
                link: ROUTES_AUTH.ACCOUNT_SELECTED.fullPath,
            },
            {
                label: 'Áreas de Terra',
                link: ROUTES_OWNER_PANEL.FIELD.fullPath,
            },
        ]);

        trackEvent(trackingEvents.fieldListPageView);
    }, []);

    const { data } = useGetOwnerFieldList({
        filters: {
            idAccess
        }
    })

    const getCenter = () => {
        if (!data?.length) return { center: [-15.788497, -47.879873], zoom: 4 };

        const bounds = new LatLngBounds([]);
        
        try {
            data.forEach((field) => {
                const coordinates = JSON.parse(field.coordinates);
                
                if (!coordinates) return;

                coordinates.forEach((point: { latitude: number, longitude: number }) => {
                    if (point && point?.latitude && point?.longitude) {
                        bounds.extend([point.latitude, point.longitude]);
                    }
                });
            });

            return { bounds, center: [-15.788497, -47.879873], zoom: 4 };
        } catch (error) {
            return { bounds: null, center: [-15.788497, -47.879873], zoom: 4 };
        }
    };

    const { bounds, center, zoom } = getCenter();

    const MapController = () => {
        const map = useMap();
        
        useEffect(() => {
            if (bounds?.isValid()) {
                map.fitBounds(bounds, { padding: [15, 15] });
            }
        }, [map, bounds]);

        return null;
    };

    return (
        <Map center={center as LatLngExpression} zoom={zoom}>
            <MapController />
            {data?.map((field, index) => {
                try {
                    let coordinates = JSON.parse(field.coordinates);
                    coordinates = coordinates.map((item: any)  => ({
                        lat: item.latitude,
                        lng: item.longitude
                    }))

                    if (!coordinates) return null;

                    return (
                        <Polygon 
                            key={index}
                            positions={coordinates}
                            pathOptions={{ color: colors.secondary600, opacity: 1}}
                        />
                    );
                } catch (error) {
                    return null;
                }
            })}
        </Map>
    )
}